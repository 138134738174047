import React from "react";
import {SubmitHandler, useForm} from "react-hook-form";

interface IFormInput {
    one: string;
    two: string;
}

function AwesomeForm() {
    const {
        register,
        handleSubmit
    } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
        console.log(data);
    };

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div><input {...register("one", {required: true, maxLength: 5})} type="text"/></div>
            <div><input {...register("two")} type="text"/></div>
            <div>
                <button>Abschigge</button>
            </div>
        </form>
    )
}

export default AwesomeForm;